define("oldham-strong/routes/theabundanceformula", ["exports", "firebase/database"], function (_exports, _database) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TheabundanceformulaRoute extends Ember.Route {
    async model() {
      const dbRef = (0, _database.ref)((0, _database.getDatabase)());
      let episodes = await (0, _database.get)((0, _database.child)(dbRef, `legacypodcast`)).then(snapshot => {
        let snapJson = snapshot.toJSON();
        let flatJson = Object.values(snapJson);
        let keyJson = Object.keys(snapJson);
        let combJson = [];
        flatJson.forEach((x, i) => {
          let newObj = {
            "url": keyJson[i],
            ...flatJson[i]
          };
          combJson.push(newObj);
        });
        return combJson;
      }).catch(error => {
        return error;
      });
      let sequenceEpisodes = episodes.sort(function (a, b) {
        return b.id - a.id;
      });
      return sequenceEpisodes;
    }

  }

  _exports.default = TheabundanceformulaRoute;
});