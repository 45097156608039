define("oldham-strong/components/pay-palsub", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
  <input type="hidden" name="cmd" value="_s-xclick">
  <input type="hidden" name="hosted_button_id" value={{promocode this.couponcode @bid}}>
  
  
  {{#if @buynow}}
  <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynow_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
  {{/if}}
  
  {{#unless @buynow}}
  <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribeCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
  {{/unless}}
  
  <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
  </form>
  
  {{#if @coupon}}
      <label>
          <Input id="cpncd" @type="text" @value={{this.couponcode}} aria-label="Promo Code" placeholder="Promo Code"/>
      </label>
  
  {{/if}}
  
  
  
  
  */
  {
    "id": "Amya9aOV",
    "block": "{\"symbols\":[\"@bid\",\"@buynow\",\"@coupon\"],\"statements\":[[10,\"form\"],[14,\"action\",\"https://www.paypal.com/cgi-bin/webscr\"],[14,\"method\",\"post\"],[14,\"target\",\"_top\"],[12],[2,\"\\n\"],[10,\"input\"],[14,3,\"cmd\"],[14,2,\"_s-xclick\"],[14,4,\"hidden\"],[12],[13],[2,\"\\n\"],[10,\"input\"],[14,3,\"hosted_button_id\"],[15,2,[30,[36,0],[[32,0,[\"couponcode\"]],[32,1]],null]],[14,4,\"hidden\"],[12],[13],[2,\"\\n\\n\\n\"],[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[10,\"input\"],[14,\"src\",\"https://www.paypalobjects.com/en_US/i/btn/btn_buynow_LG.gif\"],[14,\"border\",\"0\"],[14,3,\"submit\"],[14,\"alt\",\"PayPal - The safer, easier way to pay online!\"],[14,4,\"image\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[10,\"input\"],[14,\"src\",\"https://www.paypalobjects.com/en_US/i/btn/btn_subscribeCC_LG.gif\"],[14,\"border\",\"0\"],[14,3,\"submit\"],[14,\"alt\",\"PayPal - The safer, easier way to pay online!\"],[14,4,\"image\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"img\"],[14,\"alt\",\"\"],[14,\"border\",\"0\"],[14,\"src\",\"https://www.paypalobjects.com/en_US/i/scr/pixel.gif\"],[14,\"width\",\"1\"],[14,\"height\",\"1\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"label\"],[12],[2,\"\\n        \"],[8,\"input\",[[24,1,\"cpncd\"],[24,\"aria-label\",\"Promo Code\"],[24,\"placeholder\",\"Promo Code\"]],[[\"@type\",\"@value\"],[\"text\",[32,0,[\"couponcode\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"promocode\",\"if\",\"unless\"]}",
    "meta": {
      "moduleName": "oldham-strong/components/pay-palsub.hbs"
    }
  });

  let PayPalsubComponent = (_dec = Ember._tracked, (_class = class PayPalsubComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "coupon", _descriptor, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "coupon", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.couponcode;
    }
  })), _class));
  _exports.default = PayPalsubComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PayPalsubComponent);
});