define("oldham-strong/components/under-construction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="container-fluid">
      <div class="row justify-content-center">
          <div class="col-6">
              <img src="assets/images/oldhamstronglogo.png" class="img-fluid" />
          </div>
      </div>
      <h2 class="text-center">This page isn't complete yet. Stay Tuned!</h2>
      <h4 class="text-center">In the meantime, you can still subscribe to our online training program below.</h4>
      <div class="row justify-content-center">
          <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_s-xclick">
              <input type="hidden" name="hosted_button_id" value="7RZJKBM6QQPH8">
              <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribeCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
              <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
          </form>
      </div>
  </div>
  {{yield}}
  */
  {
    "id": "1b+U9Fjt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"container-fluid\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row justify-content-center\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-6\"],[12],[2,\"\\n            \"],[10,\"img\"],[14,\"src\",\"assets/images/oldhamstronglogo.png\"],[14,0,\"img-fluid\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"text-center\"],[12],[2,\"This page isn't complete yet. Stay Tuned!\"],[13],[2,\"\\n    \"],[10,\"h4\"],[14,0,\"text-center\"],[12],[2,\"In the meantime, you can still subscribe to our online training program below.\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row justify-content-center\"],[12],[2,\"\\n        \"],[10,\"form\"],[14,\"action\",\"https://www.paypal.com/cgi-bin/webscr\"],[14,\"method\",\"post\"],[14,\"target\",\"_top\"],[12],[2,\"\\n            \"],[10,\"input\"],[14,3,\"cmd\"],[14,2,\"_s-xclick\"],[14,4,\"hidden\"],[12],[13],[2,\"\\n            \"],[10,\"input\"],[14,3,\"hosted_button_id\"],[14,2,\"7RZJKBM6QQPH8\"],[14,4,\"hidden\"],[12],[13],[2,\"\\n            \"],[10,\"input\"],[14,\"src\",\"https://www.paypalobjects.com/en_US/i/btn/btn_subscribeCC_LG.gif\"],[14,\"border\",\"0\"],[14,3,\"submit\"],[14,\"alt\",\"PayPal - The safer, easier way to pay online!\"],[14,4,\"image\"],[12],[13],[2,\"\\n            \"],[10,\"img\"],[14,\"alt\",\"\"],[14,\"border\",\"0\"],[14,\"src\",\"https://www.paypalobjects.com/en_US/i/scr/pixel.gif\"],[14,\"width\",\"1\"],[14,\"height\",\"1\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "oldham-strong/components/under-construction.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});