define("oldham-strong/initializers/fire-base", ["exports", "oldham-strong/config/environment", "firebase/app"], function (_exports, _environment, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function
    /* application */
  initialize() {
    // application.inject('route', 'foo', 'service:foo');
    const app = (0, _app.initializeApp)(_environment.default.firebaseConfig);
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});