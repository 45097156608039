define("oldham-strong/components/audio-player", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="col-12 ap">
      <audio id="audiofile" src={{this.audio}} contorlslist="nodownload" ontimeupdate="document.getElementById('counter').innerHTML = new Date(Math.floor(this.currentTime) * 1000).toISOString().slice(11,19); let timeCalc = Math.floor((this.currentTime / this.duration) * 100) + '%'; let timeBarVal = 'width: ' + timeCalc;
      document.getElementById('timeBar').setAttribute('style', timeBarVal);" />
      <button class="btn btn-primary" {{on "click" this.play}}>{{#if this.playstate}}Pause{{else}}Play{{/if}}</button>
      <button class="btn btn-secondary" {{on "click" this.skipBack}}>
          - 15 seconds
      </button>
      <button class="btn btn-secondary" {{on "click" this.skip}}>
          + 30 seconds
      </button>
      <div class="progress mt-3">
          <div id="timeBar" class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
      </div>
      <p id="counter">00:00:00</p>
  </div>
  
  {{yield}}
  */
  {
    "id": "0Wggmrwi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"col-12 ap\"],[12],[2,\"\\n    \"],[10,\"audio\"],[14,1,\"audiofile\"],[15,\"src\",[32,0,[\"audio\"]]],[14,\"contorlslist\",\"nodownload\"],[14,\"ontimeupdate\",\"document.getElementById('counter').innerHTML = new Date(Math.floor(this.currentTime) * 1000).toISOString().slice(11,19); let timeCalc = Math.floor((this.currentTime / this.duration) * 100) + '%'; let timeBarVal = 'width: ' + timeCalc;\\n    document.getElementById('timeBar').setAttribute('style', timeBarVal);\"],[12],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,0],[\"click\",[32,0,[\"play\"]]],null],[12],[6,[37,1],[[32,0,[\"playstate\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"Pause\"]],\"parameters\":[]},{\"statements\":[[2,\"Play\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-secondary\"],[4,[38,0],[\"click\",[32,0,[\"skipBack\"]]],null],[12],[2,\"\\n        - 15 seconds\\n    \"],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-secondary\"],[4,[38,0],[\"click\",[32,0,[\"skip\"]]],null],[12],[2,\"\\n        + 30 seconds\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"progress mt-3\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,1,\"timeBar\"],[14,0,\"progress-bar\"],[14,\"role\",\"progressbar\"],[14,\"aria-valuenow\",\"75\"],[14,\"aria-valuemin\",\"0\"],[14,\"aria-valuemax\",\"100\"],[14,5,\"width: 0%\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,1,\"counter\"],[12],[2,\"00:00:00\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"on\",\"if\"]}",
    "meta": {
      "moduleName": "oldham-strong/components/audio-player.hbs"
    }
  });

  let AudioPlayerComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = class AudioPlayerComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "audio", _descriptor, this);

      _initializerDefineProperty(this, "playstate", _descriptor2, this);

      _initializerDefineProperty(this, "playTime", _descriptor3, this);

      _initializerDefineProperty(this, "audioDuration", _descriptor4, this);
    }

    play() {
      if (this.playstate == false) {
        audiofile.playbackRate = 1;
        audiofile.play();
        document.getElementById('timeBar').classList.add('progress-bar-striped', 'progress-bar-animated');
      } else {
        audiofile.pause();
        document.getElementById('timeBar').classList.remove('progress-bar-striped', 'progress-bar-animated');
      }

      this.playstate = !this.playstate;
      this.playTime = audiofile.currentTime;
      this.audioDuration = new Date(Math.floor(audiofile.duration) * 1000).toISOString().substr(11, 8); //console.log(audiofile.duration);
      //console.log(audiofile.playbackRate);
    }

    skip() {
      audiofile.currentTime += 30.0;
      this.playTime = audiofile.currentTime;
    }

    skipBack() {
      audiofile.currentTime -= 15.0;
      this.playTime = audiofile.currentTime;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "audio", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.audio;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "playstate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "playTime", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "audioDuration", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "play", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "play"), _class.prototype)), _class));
  _exports.default = AudioPlayerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AudioPlayerComponent);
});