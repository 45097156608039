define("oldham-strong/components/carousel-slide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @textOnly}}
  
  	<div id={{@order}} class="col-8 offset-2 slide text-center {{@setup}}">
  		<p>{{@text}}</p>
  		<p><strong>{{@customer}}</strong></p>
  	</div>
  
  {{else}}
  
  	<div id={{@order}} class="col-8 offset-2 col-lg-4 offset-4 slide text-center {{@setup}}">
  		<img src="assets/images/{{@image}}.jpg" alt={{@image}}>
  	</div>
  
  {{/if}}
  
  {{yield}}
  */
  {
    "id": "LC268OKP",
    "block": "{\"symbols\":[\"@order\",\"@setup\",\"@image\",\"@text\",\"@customer\",\"@textOnly\",\"&default\"],\"statements\":[[6,[37,0],[[32,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\\t\"],[10,\"div\"],[15,1,[32,1]],[15,0,[31,[\"col-8 offset-2 slide text-center \",[32,2]]]],[12],[2,\"\\n\\t\\t\"],[10,\"p\"],[12],[1,[32,4]],[13],[2,\"\\n\\t\\t\"],[10,\"p\"],[12],[10,\"strong\"],[12],[1,[32,5]],[13],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n\\t\"],[10,\"div\"],[15,1,[32,1]],[15,0,[31,[\"col-8 offset-2 col-lg-4 offset-4 slide text-center \",[32,2]]]],[12],[2,\"\\n\\t\\t\"],[10,\"img\"],[15,\"src\",[31,[\"assets/images/\",[32,3],\".jpg\"]]],[15,\"alt\",[32,3]],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,7,null]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "oldham-strong/components/carousel-slide.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});