define("oldham-strong/router", ["exports", "oldham-strong/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor() {
      super(...arguments);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('home', {
      path: '/'
    });
    this.route('construction');
    this.route('onlinetraining');
    this.route('nutritionplans');
    this.route('inpersontraining');
    this.route('competitorcoaching');
    this.route('corporatewellness');
    this.route('theabundanceformula');
    this.route('episode', {
      path: 'podcast/:id'
    });
    this.route('theabundanceformulaevent');
  });
});