define("oldham-strong/services/scroll-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ScrollTopService extends Ember.Service {
    scroll() {
      window.scrollTo(0, 0);
    }

  }

  _exports.default = ScrollTopService;
});