define("oldham-strong/helpers/promocode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function promocode(_ref) {
    let [string1, string2] = _ref;

    if (string1 == "JEANA20") {
      return 'NW5Y3TJ4W7ALJ';
    } else {
      return string2;
    }
  }

  var _default = Ember.Helper.helper(promocode);

  _exports.default = _default;
});