define("oldham-strong/components/os-carousel", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="row carousel">
  	<div class="col-12 c-bg">
  		{{yield}}
  		<button id="pre" class="btn pre" type="button" {{on "click" (fn this.nextSlide "pre")}}><i class="icon-arrow-left2"></i></button>
  		<button id="next" class="btn next" type="button" {{on "click" (fn this.nextSlide "next")}}><i class="icon-arrow-right2"></i></button>
  	</div>
  </div>
  
  
  */
  {
    "id": "VgegLLlJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"row carousel\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-12 c-bg\"],[12],[2,\"\\n\\t\\t\"],[18,1,null],[2,\"\\n\\t\\t\"],[11,\"button\"],[24,1,\"pre\"],[24,0,\"btn pre\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"nextSlide\"]],\"pre\"],null]],null],[12],[10,\"i\"],[14,0,\"icon-arrow-left2\"],[12],[13],[13],[2,\"\\n\\t\\t\"],[11,\"button\"],[24,1,\"next\"],[24,0,\"btn next\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"nextSlide\"]],\"next\"],null]],null],[12],[10,\"i\"],[14,0,\"icon-arrow-right2\"],[12],[13],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "oldham-strong/components/os-carousel.hbs"
    }
  });

  let OsCarouselComponent = (_dec = Ember._action, (_class = class OsCarouselComponent extends _component.default {
    nextSlide(btn) {
      let activeSlide = document.getElementsByClassName('activeSlide')[0];
      let previousSlide = document.getElementsByClassName('preSlide')[0];
      let nPrevSlide = {};
      let nextSlide = document.getElementsByClassName('nextSlide')[0];
      let nNextSlide = {};
      let slides = document.getElementsByClassName('slide');
      [].forEach.call(slides, function (sl, index) {
        if (sl.classList.contains('activeSlide')) {
          if (index < 2) {
            nPrevSlide = slides[slides.length - (2 - index)];
          } else {
            nPrevSlide = slides[index - 2];
          }

          if (index < slides.length - 2) {
            nNextSlide = slides[index + 2];
          } else {
            nNextSlide = slides[Math.abs(2 - slides.length + index)];
          }
        }
      });

      if (btn == "pre") {
        previousSlide.classList.add('activeSlide');
        previousSlide.classList.remove('preSlide');
        activeSlide.classList.add('nextSlide');
        activeSlide.classList.remove("activeSlide");
        nextSlide.classList.remove('nextSlide');
        nPrevSlide.classList.add('preSlide');
      } else {
        nextSlide.classList.add('activeSlide');
        nextSlide.classList.remove('nextSlide');
        activeSlide.classList.add('preSlide');
        activeSlide.classList.remove("activeSlide");
        previousSlide.classList.remove('preSlide');
        nNextSlide.classList.add('nextSlide');
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "nextSlide", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "nextSlide"), _class.prototype)), _class));
  _exports.default = OsCarouselComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OsCarouselComponent);
});